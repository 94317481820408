export default [
    {
        name: 'VERY SEXY',
        slug: 'very_sexy',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy.jpg',
        categories: [
            'collections',
        ],
        description: [],
        breadcrumb: {
            label: 'Very Sexy',
        },
        scrollable: true,
    },
    {
        name: 'DREAM ANGELS',
        slug: 'dream_angels',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels.jpg',
        categories: [
            'collections',
        ],
        description: [],
        breadcrumb: {
            label: 'Dream Angels',
        },
        scrollable: false,
    },
    {
        name: 'BODY BY VICTORIA',
        slug: 'body_by_victoria',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria.jpg',
        categories: [
            'collections',
        ],
        description: [],
        breadcrumb: {
            label: 'Body by Victoria',
        },
        scrollable: true,
    },
    {
        name: 'LOVE CLOUD',
        slug: 'love_cloud_bras',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud.jpg',
        categories: [
            'collections',
        ],
        description: [
        ],
        breadcrumb: {
            label: 'Love Cloud',
        },
        scrollable: false,
    },
    {
        name: 'VS BARE',
        slug: 'vs_bare',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare.jpg',
        categories: [
            'collections',
        ],
        description: [],
        breadcrumb: {
            label: 'VS Bare',
        },
        scrollable: false,
    },
    {
        name: 'T-SHIRT BRA',
        slug: 't_shirt',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt.jpg',
        categories: [
            'collections',
        ],
        description: [
        ],
        breadcrumb: {
            label: 'T-Shirt Bra',
        },
        scrollable: false,
    },
    {
        name: 'SEXY TEE',
        slug: 'sexy_tee',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sexy_tee.jpg',
        categories: [
            'collections',
        ],
        description: [],
        breadcrumb: {
            label: 'Sexy Tee',
        },
        scrollable: false,
    },
    // {
    //     name: 'INCREDIBLE',
    //     slug: 'incredible',
    //     image:https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible.jpg',
    //     categories: [
    //         'collections',
    //     ],
    //     description: [],
    //     breadcrumb: {
    //         label: 'Incredible',
    //     },
    //     scrollable: false,
    // },
    {
        name: 'SPORT & LOUNGE',
        slug: 'sport_lounge',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge.jpg',
        categories: [
            'collections',
        ],
        description: [
        ],
        breadcrumb: {
            label: 'Sport & lounge',
        },
        scrollable: false,
    },


    // bra styles
    {
        name: 'Demi',
        slug: 'demi',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/styles/demi.jpg',
        categories: [
            'bra_styles',
        ],
        description: [
            'Perfect for everyday wear to provide a natural lift',
        ],
        breadcrumb: {
            label: 'Demi',
        },
        scrollable: true,
    },
    {
        name: 'Full Coverage',
        slug: 'full_coverage',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/styles/full_coverage.jpg',
        categories: [
            'bra_styles',
        ],
        description: [
            'Offer smoothing, sleek style with full cup support',
        ],
        breadcrumb: {
            label: 'Full Coverage',
        },
        scrollable: false,
    },
    {
        name: 'Perfect Shape',
        slug: 'perfect_shape',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/styles/perfect_shape.jpg',
        categories: [
            'bra_styles',
        ],
        description: [
            'A smooth, sleek style with maximum comfort',
        ],
        breadcrumb: {
            label: 'Perfect Shape',
        },
        scrollable: false,
    },
    {
        name: 'Push-Up',
        slug: 'push_up',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/styles/push_up.jpg',
        categories: [
            'bra_styles',
        ],
        description: [
            'Soft, plush padding',
            'for the lift you love',
        ],
        breadcrumb: {
            label: 'Push-Up',
        },
        scrollable: true,
    },
    {
        name: 'Lightly Lined',
        slug: 'lightly_lined',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/styles/lightly_lined.jpg',
        categories: [
            'bra_styles',
        ],
        description: [
            'A natural look with a subtle and gentle lift',
        ],
        breadcrumb: {
            label: 'Lightly Lined',
        },
        scrollable: true,
        
    },
    {
        name: 'Unlined',
        slug: 'unlined',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/styles/unlined.jpg',
        categories: [
            'bra_styles',
        ],
        description: [
            'From plunge to demi and bralette, we have them all!',
        ],
        breadcrumb: {
            label: 'Unlined',
        },
        scrollable: false,
    },
    {
        name: 'Wireless',
        slug: 'wireless',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/styles/wireless.jpg',
        categories: [
            'bra_styles',
        ],
        description: [
            'Wireless bras for a comfortable, natural look',
        ],
        breadcrumb: {
            label: 'Wireless',
        },
        scrollable: true,
    },
    {
        name: 'Strapless',
        slug: 'strapless',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/styles/strapless.jpg',
        categories: [
            'bra_styles',
        ],
        description: [
            'Perfect for halters,',
            'off-the-shoulder tops',
        ],
        breadcrumb: {
            label: 'Strapless',
        },
        scrollable: true,
    },
    {
        name: 'Bra Top',
        slug: 'bra_top',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/styles/bra_top.jpg',
        categories: [
            'bra_styles',
        ],
        description: [
            'Designed with glamorous lace detailing',
        ],
        breadcrumb: {
            label: 'Bra Top',
        },
        scrollable: true,
    },
    {
        name: 'Sport',
        slug: 'sport',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/styles/sport.jpg',
        categories: [
            'bra_styles',
        ],
        description: [
            'Support and comfort for the sport you love',
        ],
        breadcrumb: {
            label: 'Sport',
        },
        scrollable: true,
    },
    {
        name: 'Nursing Bra',
        slug: 'nursing_bra',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/styles/nursing_bra.jpg',
        categories: [
            'bra_styles',
        ],
        description: [
            'Leak resistant, absorbent and beyond comfortable',
        ],
        breadcrumb: {
            label: 'Nursing Bra',
        },
        scrollable: false,
    },
];
